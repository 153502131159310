'use client';

import { Trans } from '@lingui/macro';
import { Stack } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import dynamic from 'next/dynamic';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { ActivationEmail } from 'src/api/auth';
import { BannerV6 } from 'src/components/modules/banner-v6';
import FeaturedLeagues from 'src/components/modules/featured-leagues';
import { LeaderboardTab } from 'src/components/modules/leaderboard';
import LiveSports from 'src/components/modules/live-sports';
import TopCasinoGames from 'src/components/modules/top-casino-games';
import { useMyRouter } from 'src/hooks/use-locale';
import { useQuery } from 'src/hooks/use-query';
import { doActivationEmailAtom, loggedAtom, profileAtom } from 'src/store/auth';
import { loginAtom } from 'src/store/layout';
import { Paths } from 'src/utils/constants/path';
import { getResponseMsg } from 'src/utils/constants/response-code';
import {
  ActivationExpiredDialog,
  ActivationExpiredDialogFn,
} from '../activation-expired-dialog';
import { ChangeUsername } from './components/change-username';
import { HotTournaments as HotTournamentsLoading } from './components/hot-tournaments';

const HotEvents = dynamic(() => import('src/components/modules/hot-events'), {
  ssr: false,
});

const Leaderboard = dynamic(
  () => import('src/components/modules/leaderboard').then((r) => r.Leaderboard),
  { ssr: false },
);

const HotTournaments = dynamic(
  () => import('./components/hot-tournaments').then((r) => r.HotTournaments),
  { loading: () => <HotTournamentsLoading lazyLoading />, ssr: false },
);

const ContentfulBanner = dynamic(
  () =>
    import('src/components/modules/contentful-banner').then(
      (r) => r.ContentfulBanner,
    ),
  { ssr: false },
);

const HotBets = dynamic(
  () => import('./components/hot-bet').then((r) => r.HotBets),
  { ssr: false },
);

export const Main: React.FCC = () => {
  const profile = useAtomValue(profileAtom);
  const userId = profile?.id;
  const hasUsernameChanged = profile?.hasUsernameChanged;
  const activationExpiredDialogRef = useRef<ActivationExpiredDialogFn>();
  const logged = useAtomValue(loggedAtom);
  const { enqueueSnackbar } = useSnackbar();
  const router = useMyRouter();
  const doActivationEmail = useSetAtom(doActivationEmailAtom);
  const setLogin = useSetAtom(loginAtom);

  const { token, email } = useQuery(['token', 'email']);

  const handleActivationEmail = async (body: ActivationEmail) => {
    try {
      if (profile?.emailVerified) return;

      const { code } = await doActivationEmail(body);
      if (code === '337') {
        enqueueSnackbar(getResponseMsg(code), {
          variant: 'success',
        });
      }
    } catch (error: any) {
      const { code, message } = error;
      if (code === '67') {
        if (logged) {
          activationExpiredDialogRef.current?.open();
        } else {
          setLogin(true);
        }
      } else {
        enqueueSnackbar(getResponseMsg(code) ?? message, {
          variant: 'error',
        });
      }
    } finally {
      router.replace(Paths.Home);
    }
  };

  useEffectOnce(() => {
    if (token && email) handleActivationEmail({ token, email });
  });

  return (
    <Stack>
      <BannerV6 />
      {userId && <>{!hasUsernameChanged && <ChangeUsername />}</>}

      {/* <PromotionNewCustomer /> */}
      {/* <HotTournaments /> */}
      <TopCasinoGames />
      <HotBets />
      <FeaturedLeagues />
      <HotEvents />
      <LiveSports />

      <Leaderboard
        tabs={[
          {
            value: LeaderboardTab.Tournaments,
            title: <Trans id="home.leaderboard.tournaments">Tournaments</Trans>,
          },
          {
            value: LeaderboardTab.Casino,
            title: <Trans id="casino">Casino</Trans>,
          },
          {
            value: LeaderboardTab.Sports,
            title: <Trans id="sportsbook">Sports</Trans>,
          },
        ]}
      />
      <ActivationExpiredDialog ref={activationExpiredDialogRef} />
    </Stack>
  );
};

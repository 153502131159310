import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { checkExistedAccount, setChangeUsername } from 'src/api/auth';
import LogoIcon from 'src/assets/images/logo.svg?url';
import { BaseDialog } from 'src/components/core/base-dialog';
import { LoadingButton } from 'src/components/core/loading-button';
import { AppRecaptcha } from 'src/components/core/rhf-recaptcha';
import { RHFMyInput } from 'src/components/modules/rhf/rhf-my-input';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { changeUsernameAtom, profileAtom } from 'src/store/auth';
import {
  checkContainSpecialCharacter,
  checkContainWhiteSpace,
  checkEnglishAlphabet,
  integerRegex,
} from 'src/utils/constants/config-regex';
import { MyDevices } from 'src/utils/helpers/etc';
interface FormData {
  new_username: string;
}

export const ChangeUsername: React.FCC = () => {
  const isMobile = useBreakpoint(MyDevices.MOBILE);
  const [open, setOpen] = useAtom(changeUsernameAtom);
  const handleClose = () => setOpen(false);
  const [profile, fetchProfile] = useAtom(profileAtom);
  const [touched, setTouched] = useState(false);

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm<FormData>({
    defaultValues: {
      new_username: profile?.username ?? '',
    },
    mode: 'all',
    criteriaMode: 'all',
  });
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { i18n } = useLingui();

  useEffect(() => {
    reset({ new_username: profile?.username ?? '' });
  }, [open]);

  const onSubmit = async (data: FormData) => {
    try {
      const recaptcha = await recaptchaRef.current?.executeAsync();
      if (!recaptcha) return;
      await checkExistedAccount({
        username: data.new_username,
        recaptcha,
        user_id: profile?.id,
      });

      await setChangeUsername(data);
      fetchProfile();
      setOpen(false);
    } catch (error: any) {
      const { code } = error;

      if (code === '12') {
        setError(
          'new_username',
          {
            type: 'api',
            message: t(i18n)({
              id: 'error.invalid_username',
              message:
                'This player name is either unavailable or does not meet our requirements.',
            }),
          },
          { shouldFocus: true },
        );
      }
    }
  };

  return (
    <BaseDialog
      PaperProps={{ sx: { p: 5 } }}
      showCloseIcon
      open={open}
      onClose={(e, reason) => reason !== 'backdropClick' && handleClose()}
      disableEnforceFocus
      fullScreen={isMobile}>
      {touched && <AppRecaptcha ref={recaptchaRef} />}

      <Stack
        spacing={2}
        component="form"
        noValidate
        onSubmit={(e) => {
          setTouched(true);
          handleSubmit(onSubmit)(e);
        }}>
        <img height={39} src={LogoIcon.src} alt="logo" />

        <div>
          <Typography component="div" variant="h6" sx={{ fontWeight: 700 }}>
            <Trans id="home.change_username_title">Welcome to UBet!</Trans>
          </Typography>
          <Typography
            sx={{ fontSize: 18, lineHeight: 5 / 4, fontWeight: 700, mt: 1 }}>
            <Trans id="home.change_username_sub_title">
              Let’s create your player name.
            </Trans>
          </Typography>
        </div>

        <Box component="ul" sx={{ listStyle: 'inside', p: 0, m: 0 }}>
          {[
            <Trans key="1" id="auth.change_username.subtitle_1">
              Once set, it can’t be changed.
            </Trans>,
            <Trans key="2" id="auth.change_username.subtitle_2">
              Keep it clean (no swearing or profanity).
            </Trans>,
            <Trans key="3" id="auth.change_username.subtitle_3">
              Do not use personal identifiable information.
            </Trans>,
          ].map((t, index) => (
            <Typography key={index} component="li" sx={{ fontWeight: 500 }}>
              {t}
            </Typography>
          ))}
        </Box>

        <RHFMyInput
          label={<Trans id="common.username">Player name</Trans>}
          onFocus={() => setTouched(true)}
          onBlur={() => setTouched(false)}
          controller={{
            control,
            name: 'new_username',
            rules: {
              required: {
                value: true,
                message: t(i18n)({
                  id: 'error.empty',
                  message: 'This field cannot be empty.',
                }),
              },
              validate: {
                LENGTH: (str: string) => {
                  if (str.length < 3 || str.length > 30)
                    return t(i18n)({
                      message: '3-30 characters',
                      id: 'error.username.min_length',
                    });
                },
                NO_SPECIAL: (str: string) => {
                  if (checkContainSpecialCharacter.test(str)) {
                    return t(i18n)({
                      message: 'No special characters',
                      id: 'error.username.special_chars',
                    });
                  }
                },
                NO_ONLY_NUMBER: (str: string) => {
                  if (integerRegex.test(str))
                    return t(i18n)({
                      message:
                        'Your player name cannot consist of numbers only. Please include at least one letter.',
                      id: 'error.username.no_only_number',
                    });
                },
                NORMAL_CHARS: (str: string) => {
                  const valueNonSpecialCharacter = str
                    .replace(checkContainSpecialCharacter, '')
                    .trim();

                  if (
                    !checkEnglishAlphabet.test(valueNonSpecialCharacter) &&
                    !_.isEmpty(valueNonSpecialCharacter)
                  ) {
                    return t(i18n)({
                      message: 'Only numbers and letters',
                      id: 'error.username.normal_chars',
                    });
                  }
                },
                NO_SPACE: (str: string) => {
                  if (checkContainWhiteSpace.test(str))
                    return t(i18n)({
                      message: 'No space',
                      id: 'error.no_space',
                    });
                },
              },
            },
          }}
          fullWidth
          required
          error={Boolean(errors.new_username?.message)}
          helperText={
            _.isEmpty(errors) || errors.new_username?.type === 'api' ? null : (
              <>
                <Trans id="error.username.must_be">Player name must be:</Trans>
                <Box
                  component="ul"
                  sx={{ listStylePosition: 'inside', p: 0, m: 0 }}>
                  {_.toPairs(errors.new_username?.types).map(([, msg], idx) => (
                    <li key={idx}>{msg}</li>
                  ))}
                </Box>
              </>
            )
          }
        />

        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button
            variant="outlined"
            size="large"
            fullWidth
            onClick={handleClose}>
            <Trans id="common.skip">Skip</Trans>
          </Button>
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            fullWidth
            disabled={!isValid}>
            <Trans id="common.save">Save</Trans>
          </LoadingButton>
        </Stack>
      </Stack>
    </BaseDialog>
  );
};

'use client';

import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import ScrollableTabs from 'src/components/core/scrollable-tabs';
import { useMyRouter } from 'src/hooks/use-locale';
import { useQuery } from 'src/hooks/use-query';
import { Paths } from 'src/utils/constants/path';
import BackToWallet from '../back-to-wallet';

const DepositTab = dynamic(
  () => import('./deposit-tab').then((x) => x.DepositTab),
  { ssr: false },
);
const PurchaseTab = dynamic(
  () => import('./purchase-tab').then((x) => x.PurchaseTabPage),
  { ssr: false },
);
const FiatDepositTab = dynamic(
  () => import('./fiat-tab').then((x) => x.FiatDepositTab),
  { ssr: false },
);

export enum TabKeys {
  FIAT = 'fiat',
  DEPOSIT = 'deposit',
  PURCHASE = 'purchase',
}
const DEPOSIT_TABS = [
  {
    title: <Trans id="deposit.fiat">Bank / card</Trans>,
    value: TabKeys.FIAT,
  },
  {
    title: <Trans id="deposit.crypto">Crypto</Trans>,
    value: TabKeys.DEPOSIT,
  },
  {
    title: <Trans id="funkit_deposit.crypto">Purchase crypto</Trans>,
    value: TabKeys.PURCHASE,
  },
];

const ContentPanel = ({ tab, symbol }: { tab: string; symbol: string }) => {
  switch (tab) {
    case TabKeys.FIAT:
      return <FiatDepositTab initialCoin={symbol} />;

    case TabKeys.DEPOSIT:
      return <DepositTab initialCoin={symbol} />;

    case TabKeys.PURCHASE:
      return <PurchaseTab initialCoin={symbol} />;
  }
};

export const DepositPage: NextPage = () => {
  const router = useMyRouter();
  const { coin_symbol: _symbol = '', tab } = useQuery(['coin_symbol', 'tab']);

  const activeTab =
    DEPOSIT_TABS.find((t) => t.value === tab)?.value ?? DEPOSIT_TABS[0].value;

  return (
    <>
      <Box width="100%">
        <Typography variant="18">
          <Trans id="deposit">Deposit</Trans>
        </Typography>
        <Box sx={{ mt: 1.5 }}>
          <ScrollableTabs
            tabs={DEPOSIT_TABS}
            allowScrollButtonsMobile={false}
            scrollButtons={false}
            value={activeTab}
            onChange={(_, value) => {
              router.push(Paths.Deposit, {
                query: {
                  ...(value === TabKeys.FIAT
                    ? undefined
                    : { coin_symbol: 'ETH' }),
                  tab: value,
                },
              });
            }}
            tabProps={{
              sx: {
                minWidth: `${100 / DEPOSIT_TABS.length}%`,
                fontSize: '14px !important',
              },
            }}
          />
        </Box>
        <ContentPanel tab={activeTab} symbol={_symbol} />
        <BackToWallet />
      </Box>
    </>
  );
};

import { CRYPTO_DP, FIAT_DP } from './common-constant';

export const checkContainOneNumber = /\d+/;
export const checkContainOneUppercase = /[A-Z]+/;
export const checkContainSpecialCharacter =
  /[.!@#$%^&~`<>,;:'"+\-*?^()[\]{}|\\/_=]+/;
export const checkContainWhiteSpace = /\s/;
export const checkEnglishAlphabet = /^[\w\d]+$/;
export const checkEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const otpRegex = /^\d{0,6}$/;
export const otpPastePattern = /\d{6}/g;

export const integerRegex = /^\d*$/;
export const decimalRegex = /^\d*\.?\d*$/;
export const fiatRegex = new RegExp(`^\\d*\\.?\\d{0,${FIAT_DP}}$`);
export const cryptoRegex = new RegExp(`^\\d*\\.?\\d{0,${CRYPTO_DP}}$`);

export const brazilCpfRegex = new RegExp(
  `(^(\d{3}.\d{3}.\d{3}-\d{2})|(\d{11})$)`,
);
